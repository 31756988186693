<template>
  <div class="saldo-contas font-12 font-avenir text-grey-7">
    <div v-if="!isEmbedComponent" class="at-title">CONTAS</div>
    <div v-if="!isEmbedComponent" class="m-t">
      <div class="conta-select">
        <span class="seletor">Saldo em todas as contas <u-icon name="caret-down" type="fa" /></span>
      </div>
      <div v-if="stats" class="saldo positive">
        <span v-if="!hideMoneyInfo">R$ {{  stats.saldo.valor|moeda }}</span>
        <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
      </div>
      <div v-else><u-fake-loader /></div>
      <div class="previsao">
        <div class="lbl">Previsão de fechamento do mês</div>
        <div class="value" v-if="stats" :class="{'text-negative': calculos.saldo < 0}">
          <span v-if="!hideMoneyInfo">R$ {{ calculos.resultadoPrevistoMes|moeda }}</span>
          <span v-else class="hidden-bar non-selectable">R$ 9999,99</span>
        </div>
        <div class="value" v-else><u-fake-loader /></div>
      </div>
    </div>
    <e-row class="m-t">
      <e-col>
<!--        <u-btn @click="$emit('new')" class="app-btn font-12" color="green" no-caps>
          <div class="flex justify-between">
            <span>+ Nova {{ type === 'r' ? 'Receita' : (type === 'd' ? 'Despesa' : 'Conta') }}</span> <span class="font-10 m-l m-r-n-xs">F2</span>
          </div>
        </u-btn>-->
        <u-btn @click="click" no-caps color="green" class="app-btn font-12 no-border-radius-right">
          <span>+ Nova {{ type === 'r' ? 'Receita' : (type === 'd' ? 'Despesa' : 'Conta') }}</span> <span class="font-10 m-l m-r-n-xs">F2</span>
        </u-btn>
        <u-btn icon="sort-down" icon-type="fa" no-caps class="simple-btn p-btn-down">
          <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
            <div>
              <ul>
                <menu-options-item @click="$emit('new', 'r')" label="Novo Receita" close  />
                <menu-options-item @click="$emit('new', 'd')" label="Nova Despesa" close  />
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </e-col>
    </e-row>
  </div>
</template>

<script>
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {UPopover, UFakeLoader} from "uloc-vue"

export default {
  name: "FinSaldoContas",
  props: ['type', 'stats', 'isEmbedComponent', 'hideMoneyInfo'],
  components: {
    ECol,
    ERow,
    MenuOptionsItem,
    UPopover,
    UFakeLoader
  },
  computed: {
    s() {
      if (this.stats) {
        return this.stats
      }
      return {}
    },
    calculos() {
      let totalReceitas = 0
      let totalDespesas = 0
      let saldo = 0
      let resultadoPrevistoMes = 0
      const s = this.s
      if (s.saldo && s.saldo.valor) {
        saldo = saldo + Number(s.saldo.valor)
      }
      if (s.contasPagarMes && s.contasPagarMes.valor) {
        totalDespesas = totalDespesas + Number(s.contasPagarMes.valor)
      }
      if (s.contasReceberMes && s.contasReceberMes.valor) {
        totalReceitas = totalReceitas + Number(s.contasReceberMes.valor)
      }
      resultadoPrevistoMes = totalReceitas - totalDespesas
      saldo = (saldo + totalReceitas) - Math.abs(totalDespesas)
      return {
        saldo,
        resultadoPrevistoMes,
        totalReceitas,
        totalDespesas
      }
    }
  },
  methods: {
    click () {
      if (this.type !== 'r' && this.type !== 'd') {
        this.$refs.popover.show()
        return
      }
      this.$emit('new', this.type)
    }
  }
}
</script>
