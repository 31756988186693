export default {
    id: null,
    search: null,
    descricao: null,
    tipo: null,
    categoria: null,
    createdAt: null,
    dataCompetencia: null,
    dataVencimento: null,
    conta: [],
    pessoa: null,
    processo: null,
    repetir: false,
    pago: null,
    data1: null,
    data2: null,
    tipoData: 'vencimento',
    dueFilter: false,
    centroCusto: null,
    formaPagamento: null,
    status: null,
    relationEntityId: null,
    relationEntity: 'leilao',
    dedutivel: null
}
